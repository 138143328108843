import { render, staticRenderFns } from "./ledger-singlecurrency.vue?vue&type=template&id=5dd71c80&"
import script from "./ledger-singlecurrency.vue?vue&type=script&lang=ts&"
export * from "./ledger-singlecurrency.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports